@import "../node_modules/react-toastify/dist/ReactToastify.css";
@import "../node_modules/react-datepicker/dist/react-datepicker.css";

:root {
	--toastify-color-info: #2488cf;
  --toastify-color-success: #3d8f3f;
  --toastify-color-warning: #edb428;
  --toastify-color-error: #cf2b34;

	//Used only for colored theme
	--toastify-text-color-info: #fff;
	--toastify-text-color-success: #fff;
	--toastify-text-color-warning: #333;
	--toastify-text-color-error: #fff;
}
.Toastify__toast-theme--colored.Toastify__toast--warning .Toastify__close-button > svg {
	color: var(--toastify-text-color-warning);
}

.bg-eggplantDark {
	background-color: var(--mui-palette-eggplant-dark);
	color: #fff;
}
.bg-maroonDark {
	background-color: var(--mui-palette-maroon-dark);
	color: #fff;
}
.bg-seafoamDark {
	background-color: var(--mui-palette-seafoam-dark);
	color: #fff;
}
.bg-slateDark {
	background-color: var(--mui-palette-slate-dark);
	color: #fff;
}

.styled-datepicker {
	&[disabled] {
		pointer-events: all;
		cursor: not-allowed;
		input, label, .react-datepicker__calendar-icon, .react-datepicker__close-icon:after {
			color: #aaa !important;
			pointer-events: none;
		}
		&[mode="dark"] {
			input, label, .react-datepicker__calendar-icon, .react-datepicker__close-icon:after {
				color: #999 !important;
				pointer-events: none;
			}
		}
	}
	label {
		font-weight: 600;
		font-size: 0.85rem;
		line-height: 1;
		margin-bottom: 4px;
		display: block;
	}

	.react-datepicker {
		border: none;
		border-radius: 4px;
	}

	.react-datepicker-wrapper {
		width: 100%;
	}

	.react-datepicker__month-container, .react-datepicker__header, .react-datepicker__month {
		border: none;
	}

	input {
		width: 100%;
		padding: 6px 25px 5px 10px;
		border-radius: 4px;
		border: none;
		height: 31px;
		font-family: inherit;
		font-size: 1rem;
		&:focus {
			outline: none;
			border: none;
		}
	}

	.react-datepicker__tab-loop {
		& .react-datepicker {
			display: flex;
		}
		& .react-datepicker__day {
			border-radius: 100%;
			&:hover, &--selected {
				color: #fff;
			}
		}
	}

	.react-datepicker__calendar-icon {
		padding: 0;
		top: 50%;
		transform: translateY(-50%);
		right: 14px;
		transition: color ease-in-out .1s;
		cursor: pointer;
		width: 19px;
		height: 19px;
	}

	.react-datepicker__close-icon {
		padding: 0;
		right: 40px;
		height: 19px;
		width: 19px;
		top: 50%;
		transform: translateY(-50%);
		&:after {
			background-color: transparent;
			height: 19px;
			width: 19px;
			padding: 0;
			font-size: 30px;
			line-height: 0;
			transition: color ease-in-out .1s;
		}
	}
}